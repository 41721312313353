export const en = {
    menu: {
        about: "About",
        privacy: "Privacy",
        terms: "Terms",
        contactUs: "Contact Us",
        ENKRIPWeb: "{{APP_NAME}} Web",
        home: "Home",
        help: "Help",
        policy:"Policy",
        policySubMenu:{
           staySafe:"Stay Safe ",
           childProtection:"Child Protection ",
        },
        helpSubMenu:{
            deleteAccount: "Delete Account",
            manageProfile: "Manage Profile",
            featuresToCommunicate: "Features to Communicate",
            profileCreation: "Profile Creation",
            editProfileFeatures: "Edit Profile Features",
            createAndSharePost: "Create and Share Post",
            exploreAndViewPost: "Explore and View Post",
            chatsAndCalls: "Chats and Calls",
            updateAccount: "Update Your Account Settings",
            interest: "Modify Your Home Feed Interest",
            notification: "Notification Settings",
            language: "Switch the Language",
            linkAccount: "Link Account with Web Page",
            inviteFriends: "Invite Your Friends",
            logout: "Logout of ENKRIP",
            settings: "Adjust Your Settings"
        }
    },
    genericContent:{
        email: "Email",
        phoneNumber: "Phone Number",
        mailingAddress: "Mailing Address",
        joinEnkrip: "Join {{APP_NAME}}",
        note: "Note",
        or: "OR"
    },
    pages: {
    

        home: {
            mainBanner:{
                title: "Connect with Friends & Family",
                desc: "Stay connected with those who matter most, no matter where life takes you. Chat, video call, and share your moments effortlessly.",
                cardSection1:{
                    title: "Empowering People to Build Communities",
                    desc: "Our app is dedicated to fostering safe, inclusive spaces where everyone can connect, share, and thrive."
                },
                cardSection2: {
                    title: "Feel Like You're Right There",
                    desc: "Whether through text, voice, or video, stay close to the people who matter on both iOS and Android."
                },
                cardSection3: {
                    title: "Share Confidently with End-to-End Encryption",
                    desc: "Communicate openly and securely, knowing your conversations are protected by industry-leading end-to-end encryption."
                }
            },
            banner1:{
                title: "Never Miss a Moment with Voice and Video Calls",
                desc: "From group calls with friends to quick check-ins with family, enjoy crystal-clear voice and video that make you feel like you're in the same room."
            },
            banner2:{
                title: "Explore Engaging Content",
                desc: "Dive into a world of photos, videos, and profiles tailored to your interests. Discover and connect with new content effortlessly."
            },
            banner3:{
                title: "Capture and Share Your Everyday Moments",
                desc: "From a group call to classmates to a quick call with mom, feel like you're in the same room with voice and video calls."
            }
        },
        about: {
            title: "About {{APP_NAME}}",
            para1: "At {{APP_NAME}}, we're dedicated to bringing people closer together by empowering them to build vibrant, secure communities. Our app is more than just a communication tool—it's a platform designed to make staying connected with friends, family, and like-minded individuals effortless and meaningful.",
            para2: "With a commitment to privacy and security, {{APP_NAME}} ensures that your conversations and interactions remain private through cutting-edge end-to-end encryption. Whether you're sharing life's moments, making plans with a group, or simply catching up with someone special, {{APP_NAME}} makes it all possible.",
            para3: "We believe in creating a safe, inclusive space where everyone can connect, share, and thrive. Available on both iOS and Android, our app is designed to make you feel like you're right there with the people who matter most, no matter where they are."
        },
        privacy: {
            title: "{{APP_NAME}} Privacy Policy",
            para1: "Welcome to {{APP_NAME}}! Your privacy is important to us. This Privacy Policy explains how PT. Cahaya Sinergi Sentosa (“we”, “us”, “our”) collects, uses, discloses, and protects your information when you use our social media and chat application, {{APP_NAME}} (“App”). By using {{APP_NAME}}, you agree to the collection and use of information in accordance with this policy.",
            subtitle1:{
                title: "1. Information We Collect",
                subtitle1:{
                    title: "1.1 Personal Information",
                    para1: "When you register and use our App, we may collect the following personal information:",
                    para2: {
                        label: "Name:",
                        desc: "To identify and personalize your account."
                    },
                    para3: {
                        label: "Email address:",
                        desc: "To communicate with you, send updates, and verify your account."
                    },
                    para4: {
                        label: "Phone number:",
                        desc: "To connect you with your contacts and for security purposes."
                    },
                    para5: {
                        label: "Profile picture:",
                        desc: "To personalize your account."
                    },
                    para6: {
                        label: "Contacts:",
                        desc: "With your consent, we may access your contact list to help you connect with friends who also use {{APP_NAME}}."
                    }
                },
                subtitle2: {
                    title: "1.2 Content",
                    para1: "We collect the content you create, share, and communicate through the App, including:",
                    para2:{
                        label: "Photos and videos:",
                        desc: "Visual content you upload or share."
                    },
                    para3:{
                        label: "Messages and chat history:",
                        desc: "Your communication with other users."
                    },
                    para4:{
                        label: "Comments and likes:",
                        desc: "Your interactions with other users’ content."
                    },
                },
                subtitle3: {
                    title: "1.3 Usage Information",
                    para1: "We collect information about your interactions with the App, such as:",
                    para2:{
                        label: "Date and time of access:",
                        desc: "To track app usage and improve user experience."
                    },
                    para3:{
                        label: "Pages viewed and features used:",
                        desc: "To understand how you use the App and optimize our services."
                    },
                    para4:{
                        label: "Links clicked:",
                        desc: "To analyze your preferences and interests."
                    },
                    para5:{
                        label: "IP address:",
                        desc: "To identify your general location and enhance security."
                    },
                    para6:{
                        label: "Device information:",
                        desc: "Such as device ID, operating system, browser type, to ensure compatibility and optimal performance."
                    },
                }
            },
            subtitle2:{
                title: "2. How We Use Your Information",
                para1: "We use the information we collect for the following purposes:",
                para2:{
                    label: "To provide, maintain, and improve the App:",
                    desc: "Ensuring the App functions properly and fixing technical issues."
                },
                para3:{
                    label: "To personalize your experience:",
                    desc: "Presenting relevant content and recommendations."
                },
                para4:{
                    label: "To communicate with you:",
                    desc: "Sending updates, news, and promotional materials related to {{APP_NAME}}."
                },
                para5:{
                    label: "To analyze usage and trends:",
                    desc: "Understanding how users interact with the App and conducting research to improve our services."
                },
                para6:{
                    label: "To ensure security and prevent misuse:",
                    desc: "Protecting our users and maintaining the integrity of the App."
                }
            },
            subtitle3:{
                title: "3. Sharing Your Information",
                para1: "We may share your information with third parties under the following circumstances:",
                para2:{
                    label: "With your consent:",
                    desc: "We will request your consent before sharing your personal information with third parties."
                },
                para3:{
                    label: "With service providers:",
                    desc: "We work with service providers who assist us in operating the App, such as cloud storage, analytics, and technical support."
                },
                para4:{
                    label: "When required by law:",
                    desc: "We may disclose your information if required by law or if we believe that such disclosure is necessary to protect our rights or comply with legal processes."
                },
                para5:{
                    label: "In connection with business transactions:",
                    desc: "In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction."
                },
            },
            subtitle4:{
                title: "4. Data Security",
                para1: "We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.",
            },
            subtitle5:{
                title: "5. Your Rights",
                para1: "You have the following rights concerning your personal information:",
                para2:{
                    label: "Access:",
                    desc: "You can request a copy of your personal information that we hold."
                },
                para3:{
                    label: "Correction:",
                    desc: "You can request correction of inaccurate or incomplete information."
                },
                para4:{
                    label: "Deletion:",
                    desc: "You can request deletion of your personal information under certain circumstances."
                },
                para5:{
                    label: "Restriction of Processing:",
                    desc: "You can request the restriction of processing of your personal information under certain circumstances."
                },
                para6:{
                    label: "Data Portability:",
                    desc: "You can request a copy of your personal information in a machine-readable and commonly used format."
                },
                para7:{
                    label: "Objection:",
                    desc: "You can object to the processing of your personal information under certain circumstances."
                }
            },
            subtitle6:{
                title: "6. Children’s Privacy",
                para1: "Our App is not intended for individuals under the age of 13. We do not knowingly collect personal information from individuals under 13. If we become aware that we have collected personal information from an individual under 13, we will take steps to delete such information.",
            },
            subtitle7:{
                title: "7. Cookies and Other Tracking Technologies",
                para1: "We use cookies and similar tracking technologies to collect usage information and improve user experience. Cookies are small files stored on your device that help us recognize you and remember your preferences. You can set your browser to refuse all cookies or to notify you when cookies are being sent. However, some features of the App may not function properly without cookies.",
            },
            subtitle8:{
                title: "8. Links to Third Party Sites",
                para1: "Our Application may contain links to third party sites or services that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party sites or services.",
            },
            subtitle9:{
                title: "9. Changes to This Privacy Policy",
                para1: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
            },
            subtitle10:{
                title: "10. Contact Us",
                para1: {
                    desc: "If you have any questions about this Privacy Policy, please contact us at:",
                    email: "Email:"
                },
                para2: "By using Enkrip, you agree to the terms of this Privacy Policy. If you do not agree to these terms, please do not use the Application."
            }
        },
        terms: {
            title: "{{APP_NAME}} Terms of Use",
            subtitle1: {
                title: "1. Introduction",
                para1: "Welcome to {{APP_NAME}}! These Terms of Use (“Terms”) govern your use of our mobile and web applications (“App”). By accessing or using the App, you agree to comply with these Terms. If you do not agree with these Terms, you must not use the App."
            },
            subtitle2: {
                title: "2. Acceptance of Terms",
                para1: "2.1. By registering, accessing, or using the App, you accept and agree to be bound by these Terms and our Privacy Policy.",
                para2: "2.2. If you are using the App on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms."
            },
            subtitle3: {
                title: "3. Modifications to Terms",
                para1: "3.1. {{APP_NAME}} reserves the right to update or modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website and App or by email.",
                para2: "3.2. Your continued use of the App following any changes constitutes your acceptance of the revised Terms."
            },
            subtitle4: {
                title: "4. User Accounts",
                para1: "4.1. To access certain features of the App, you must create an account. You agree to provide accurate, current, and complete information during the registration process.",
                para2: "4.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
                para3: "4.3. You agree to notify us immediately of any unauthorized use of your account or any other breach of security."
            },
            subtitle5: {
                title: "5. Privacy Policy",
                para1: "5.1. Our Privacy Policy explains how we collect, use, and share your information. By using the App, you consent to the collection and use of your information as described in our Privacy Policy."
            },
            subtitle6: {
                title: "6. Use of the App",
                para1: "6.1. You agree to use the App in accordance with these Terms and all applicable laws and regulations.",
                para2: "6.2. You must not use the App to transmit any harmful or illegal content, including but not limited to, viruses, malware, or any content that infringes on the rights of others."
            },
            subtitle7: {
                title: "7. User Content",
                para1: "7.1. You retain ownership of any content you upload, post, or share through the App (“User Content”). By submitting User Content, you grant {{APP_NAME}} a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, and display such content in connection with the App.",
                para2: "7.2. You represent and warrant that you own or have the necessary rights to upload, post, or share User Content and that your User Content does not violate any third-party rights or applicable laws."
            },
            subtitle8: {
                title: "8. Prohibited Conduct",
                para1: "8.1 You agree not to engage in any of the following prohibited activities:",
                para2: "Using the App for any illegal purpose or to promote illegal activities.",
                para3: "Impersonating another person or entity or falsely stating your affiliation with a person or entity.",
                para4: "Interfering with or disrupting the security, integrity, or performance of the App.",
                para5: "Attempting to gain unauthorized access to the App or its related systems or networks."
            },
            subtitle9: {
                title: "9. Intellectual Property",
                para1: "9.1. The App and all content and materials included therein, such as text, graphics, logos, images, and software, are the property of {{APP_NAME}} or its licensors and are protected by intellectual property laws.",
                para2: "9.2. You are granted a limited, non-exclusive, non-transferable, revocable license to access and use the App for personal, non-commercial purposes.",
                para3: "9.3. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as permitted by these Terms."
            },
            subtitle10: {
                title: "10. Termination",
                para1: "10.1. {{APP_NAME}} reserves the right to terminate or suspend your access to the App at any time, without notice, for any reason, including if you breach these Terms.",
                para2: "10.2. Upon termination, your right to use the App will immediately cease, and you must destroy all copies of downloaded or obtained materials from the App."
            },
            subtitle11: {
                title: "11. Limitation of Liability",
                para1: "11.1. {{APP_NAME}} shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of or inability to use the App.",
                para2: "11.2. {{APP_NAME}} makes no warranties or representations about the accuracy or completeness of the App’s content or the content of any websites linked to the App."
            },
            subtitle12: {
                title: "12. Indemnification",
                para1: "12.1. You agree to indemnify, defend, and hold harmless {{APP_NAME}}, its affiliates, officers, directors, employees, agents, and licensors from any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of your violation of these Terms or your use of the App."
            },
            subtitle13: {
                title: "13. Governing Law and Jurisdiction",
                para1: "13.1. These Terms shall be governed by and construed in accordance with the laws of Indonesia, without regard to its conflict of law principles.",
                para2: "13.2. Any legal suit, action, or proceeding arising out of or related to these Terms or the App shall be instituted exclusively in the courts of Indonesia."
            },
            subtitle14: {
                title: "14. Miscellaneous",
                para1: "14.1. These Terms constitute the entire agreement between you and {{APP_NAME}} regarding the use of the App.",
                para2: "14.2. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.",
                para3: "14.3. {{APP_NAME}}’s failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by {{APP_NAME}} in writing."
            },
            subtitle15: {
                title: "15. Contact Information",
                para1: {
                    desc: "15.1. If you have any questions or concerns about these Terms or the App, please contact us at"
                }
            }
        },
        contactUs: {
            title: "Contact Us",
            desc: "We're here to help! If you have any questions, feedback, or need assistance, please don't hesitate to reach out. You can contact us through any of the following methods:"
        },
        policy:{
            staySafe:{
                title:" {{APP_NAME}} Stay Safe",
                title1:"Education and Awareness",
                para1: "At ENKRIP, your safety is our priority. We are committed to creating a platform where users can connect and share securely. This page provides essential resources and guidance to help you stay safe online. Whether you’re a parent, child, or active community member, these tips and tools will empower you to navigate the digital world confidently and responsibly.",
            subtitle1:{
               title: "1. Safety Guidelines: Identify and Report Abuse",
               para1:"Staying vigilant is key to maintaining a safe online environment. Here’s how you can identify and report abuse:",
             }, 
            subtitle2:{
                title:"1.1 Recognize Red Flags: Look out for warning signs such as:",
                para1:"Unsolicited Requests: Someone asking for personal information, photos, or videos.",
                para2:"Inappropriate Behavior: Users sending messages that are overly personal, sexually explicit, or make you feel uncomfortable.",
                para3:"Persistent Contact Attempts: If someone repeatedly tries to contact you despite being ignored or blocked.",
                para4:"Grooming Behaviors: Gradual attempts to gain trust for exploitative purposes.",
            },
            subtitle3:{
                title:"1.2 What to Do :",
                para1:{
                    text1:"Use the",
                    label:"Report Button",
                    para4:"on ENKRIP to flag suspicious content or accounts. Our moderation team will review and act within 24 hours.",
                 },
              
                para2:" Block the User: Prevent further interaction by using the blocking feature.",
                para3:"Document the Incident: Take screenshots or save messages for evidence if needed by authorities.",
    
            },
            subtitle4:{
                title:"2. Tips for Parents and Guardians",
                para1:"Protecting your child online requires active participation and open communication. Here’s how you can help:",
            
            },
            subtitle5:{
                title:"2.1 Supervise and Monitor:",
                para1:"Check your child’s friends list, messages, and activity regularly.",
                title1:"2.2 Teach Digital Boundaries:",
                para2:"Explain the importance of keeping personal information private, such as their school name, address, or phone number.",
                para3:"Encourage them to share their online experiences with you, especially if something seems suspicious.",
                title2:"2.3 Foster Awareness:",
                para4:"Teach your child to differentiate between a healthy online interaction and potential grooming attempts.",
                para5:"Share this page with them and discuss real-life examples to build understanding",
            },
            subtitle6:{
                title:"3. Online Safety Tips for Children",
                para1:"Children are more vulnerable to online risks, so they must be equipped with the knowledge to protect themselves. Here’s what kids should do:",
                title1:"3.1 Stay Private:",
                para2:"Never share personal information like your full name, address, phone number, or school online.",
                para3:"Use a profile picture that doesn’t reveal your face or surroundings if possible.",
                title2:"3.2 Be Selective with Friends:",
                para4:"Only accept friend requests from people you know in real life.",
                para5:"If someone you don’t recognize messages you, don’t respond—report and block them.",
                title3:"3.3 Speak Up",
                para6:" If you feel uncomfortable or unsure about something online, tell a parent, guardian, or trusted adult immediately.",
                para7:"Remember, it’s never your fault if someone makes you feel unsafe online.",

             },
             subtitle7:{
                title:"4. Emergency Support",
                para:"If you or someone you know is facing online abuse or harassment, help is available. ENKRIP is committed to supporting users in distress by connecting them with reliable resources:",
                title1:"4.1 Contact Local Support:",
                para1:"Komnas Perlindungan Anak (National Child Protection Commission): Dial 129 or visit their website.",
                para2:"Lembaga Perlindungan Anak Indonesia (LPAI): Call +62 21 319-01535.",
                title2:"4.2 In-App Support:",
                para3:"Use ENKRIP’s Help Center to get immediate assistance from our support team.",
                title3:"4.3 When to Reach Out:",
                para4:"If you experience harassment, receive explicit content, or feel unsafe interacting with someone online.",

             },
             subtitle8:{
                title:"5. Protecting Your Privacy on ENKRIP",
                para1:"Your privacy is your power. Follow these steps to maintain control over your digital presence:",
                title1:"5.1 Review Your Privacy Settings:",
                para2:"Access your settings and customize who can see your profile, contact you, or interact with your posts.",
                title2:"5.2 Think Before You Share:",
                para3:"Avoid sharing personal or sensitive content that could be misused.",
                para4:"Remember, anything shared online can be saved or shared further, even if deleted.",
                title3:"5.3 Clean Your Contacts:",
                para5:"Periodically review your friends list and remove or block users you don’t trust or recognize.",

             },
             subtitle9:{
                title:"6. Digital Literacy for a Safer Community",
                para1:"A strong community is built on mutual respect and safety. Here’s how you can contribute:",
                title1:"6.1 Follow Community Guidelines:",
                para2:"Familiarize yourself with ENKRIP’s rules to ensure your behavior aligns with our standards",
                title2:" 6.2 Be an Upstander:",
                para3:"If you see something wrong—such as bullying, harassment, or exploitation—report it immediately.",
                para4:"Encourage friends and family to do the same.",
                title3:"6.3 Educate Yourself:",
                para5:"Stay updated on the latest online safety practices and share what you learn with others.",
             },
             subtitle10:{
                title:"7. Resources and Tools",
                para1:"To help you navigate the digital world safely, ENKRIP provides a range of resources:",
                para2:"Privacy Policy",
                para3:"Terms of Use",
                para4:"Stay Safe with ENKRIP",
               title1:"Useful Links:",
               para5:"National Child Protection Commission, Indonesia",
               para6:"Stay Safe Online",

               para7:"By staying informed and vigilant, you contribute to a safer and more supportive community on ENKRIP. Together, we can create a digital space free from harm."
             }

            },
            childProtection:{
                title:" {{APP_NAME}} Standards Against Child Sexual Abuse and Exploitation",
                subtitle1:{
                    titel1:"1. Zero-Tolerance Policy",
                    para1:"ENKRIP strictly prohibits child sexual abuse, exploitation, and related activities. Any user found violating this standard will be permanently banned and reported to relevant authorities."
                },
                subtitle2:{
                    titel1:"2. Age Verification",
                    para1:"All users must verify their age during registration. ENKRIP employs secure and robust age verification systems to prevent underage access and unauthorized use."
                },
                subtitle3:{
                    titel1:"3. Content Moderation",
                    para1:"ENKRIP employs advanced artificial intelligence to detect and flag inappropriate or harmful content, including child sexual abuse material (CSAM).",
                    para2:"All flagged content is reviewed by a dedicated and trained human moderation team.",
                    para3:"Proactive scanning of all uploaded content is conducted to identify and remove CSAM in compliance with international standards.",
                },
                subtitle4:{
                    titel1:"4. Reporting Mechanisms",
                    para1:"ENKRIP provides a prominent and accessible reporting button for users to report suspicious content, behavior, or accounts.",
                    para2:"All reports are reviewed within 24 hours by a specialized team, with escalations to law enforcement agencies when necessary."
                },
                subtitle5:{
                    titel1:"5.User Data Protection",
                    para1:"ENKRIP ensures that all user data is encrypted and access is restricted to authorized personnel only.",
                    para2:"Sensitive data is stored securely and only for as long as necessary to fulfill its purpose or comply with legal obligations",
                },subtitle6:{
                    titel1:"6. Education and Awareness",
                    para1:"ENKRIP provides educational resources within the app, including:",
                    para2:"Safety guidelines for identifying and reporting abuse.",
                    para3:"Tips for parents and guardians to help their children stay safe online.",
                    para4:"Access to emergency hotlines and support services for victims.",
                    para5:"Stay Safe with ENKRIP",
                },subtitle7:{
                    titel1:"7. Collaboration with Authorities",
                    para1:"All reported and discovered incidents regarding child abuse will be reported to the law enforcement and child protection organizations."
                },subtitle8:{
                    titel1:"8. Regular Audits and Transparency",
                    para1:"ENKRIP conducts periodic safety audits to ensure compliance with these standards.",
                },subtitle9:{
                    titel1:"9. Strict Enforcement",
                    para1:" ENKRIP permanently bans any user found violating these standards.",
                    para2:"Verified cases are reported to appropriate authorities for legal action",
                },subtitle10:{
                    titel1:"10. Community Engagement",
                    para1:"ENKRIP fosters a culture of safety by encouraging users to report harmful content and recognize responsible behavior. ENKRIP’s community guidelines emphasize respect, safety, and inclusivity."
                },subtitle11:{
                    titel1:"11. Legal and Regulatory Compliance",
                    para1:"ENKRIP adheres to all relevant local and international laws, including:",
                    para2:"Children’s Online Privacy Protection Act (COPPA)",
                    para3:"General Data Protection Regulation (GDPR-K)",
                    para4:"Other applicable child protection laws and standards.",
                    
                },
                subtitle12:{
                    titel1:"12. Prevention of Exploitation Tools",
                    para1:"ENKRIP incorporates tools to prevent exploitation, such as:",
                    para2:"Blocking and muting functionalities.",
                    para3:"Restricting direct messages to trusted contacts for minors.",
                    para4:"AI-driven behavioral pattern detection to identify and mitigate potential exploitation risks.",    

                },subtitle13:{
                    titel1:"13. Enforcement and Accountability",
                    para1:"ENKRIP is committed to the rigorous enforcement of these standards. Any breach will result in immediate action, including removal of content, suspension or termination of accounts, and cooperation with legal authorities to ensure justice.",
                },subtitle14:{
                    titel1:" 14. Resources",
                    para1:"Privacy Policy",
                    para2:"Terms of Use",
                    para3:"Stay Safe with ENKRIP",
                    title2:"Useful Links :",
                    para4:"National Child Protection Commission, Indonesia", 
                    para5:"Stay Safe Online",   

                }

                
               
              }
        },
       
        deleteAccount: {
            title: "Delete Account",
            desc: "Below are the steps for deleting an account,",
            step1: "Log in to your ENKRIP account on Mobile App",
            step2: "Go To 'Profile page' by tapping on profile picture (top left of the screen) > Settings (top right of the screen) > Delete My Account",
            step3: "Select a reason and Click \"Continue\"",
            step4: "Enter the Mobile number and OTP",
            step5: "In the Account Delete page, Click \"Continue\"",
            step6: "Account will be deleted immediately once the \"Delete\" button is clicked",
        },
        manageProfile:{
            title: "Manage Your Profile",
            profileCreation: {
                title: "Profile Creation",
                signUp:{
                    title: "1.1 Sign Up and Create a Profile",
                    note: "You must be above 13 years old to register in ENKRIP Application.",
                    indonesianUser:{
                        title: "To Sign Up and Create a Profile if You are an Indonesian User:",
                        step1: "Install the “Enkrip” App from the Google Play store or Apple App store.",
                        step2: "Launch the Application.",
                        step3: "Enter the phone number and click the “Continue” button.",
                        step4: "In the confirmation popup, confirm the phone number by clicking the “Continue” button.",
                        step5: "Enter the code that you get through the call.",
                        step6: "If you didn’t get the call after 60 seconds, click the “Verify via Whatsapp” option and confirm that the entered phone number is registered in WhatsApp by clicking the “Continue” button in the confirmation popup.",
                        step7: "Enter the code that you get through WhatsApp.",
                        step8: "Click the “Verify” button once the code is entered.",
                        step9: "In the Let’s Set Your Profile page, enter your username and display name.",
                        step10: "Click the “Continue Registration” button.",
                        step11: "In the Personalize Your Profile page, enter the Firstname, Lastname, Email ID, DOB, and Gender.",
                        step12: "Entering Bio is not mandatory.",
                        step13: "Click the “Continue” button.",
                        step14: "Profile will be created successfully."
                    },
                    nonIndonesianUser:{
                        title: "To Sign Up and Create a Profile if You are a Non-Indonesian User:",
                        step1: "Get an invite friend link from your Indonesian friend.",
                        step2: "View the webpage of invite friend.",
                        step3: "Select your country code and enter the phone number.",
                        step4: "Click the “Get OTP” button and enter the OTP received through the SMS. Click the “Generate Invite Code” button.",
                        step5: "Install the “Enkrip” App from the Google Play store or Apple App store.",
                        step6: "Launch the Application.",
                        step7: "Select your country code.",
                        step8: "Enter the phone number and click the “Continue” button.",
                        step9: "In the confirmation popup, confirm the phone number by clicking the “Continue” button.",
                        step10: "Enter the invite code in the Invite Code popup and click the “Continue” button.",
                        step11: "Enter the OTP received through the SMS.",
                        step12: "Click the “Verify” button once the code is entered.",
                        step13: "In the Let’s Set Your Profile page, enter your username and display name.",
                        step14: "Setting a profile pic is not mandatory.",
                        step15: "Click the “Continue Registration” button.",
                        step16: "In the Personalize Your Profile page, enter the Firstname, Lastname, Email ID, DOB, and Gender.",
                        step17: "Entering Bio is not mandatory.",
                        step18: "Click the “Continue” button.",
                        step19: "Profile will be created successfully."
                    }
                },
                profilePage:{
                    title: "1.2 Profile Page",
                    desc: "Launch the application and sign in to the application.",
                    yourProfilePage:{
                        title: "Your Profile Page:",
                        step1: "On the Home page, click the profile icon displayed in the top-left corner to view your profile page.",
                        step2: "On your Profile page:",
                        step3: "Profile Username and Display name will be displayed.",
                        step4: "Posts created using this account will be displayed.",
                        step5: "Edit profile and Settings option will be displayed on this page.",
                        step6: "Share Your Profile using the “Share Profile” button.",
                        step7: "Discover People option suggests people to follow who are known by you and your followers."
                    },
                    otherUserProfilePage:{
                        title: "Other User Profile Page:",
                        step1: "On the Home page, click the profile icon displayed in the post.",
                        step2: "On the other user Profile page:",
                        step3: "Profile Username and Display name will be displayed.",
                        step4: "Posts created using this account will be displayed.",
                        step5: "Follow option alone will be displayed when both of you are not following each other.",
                        step6: "Follow back option will be displayed when the user follows you.",
                        step7: "Unfollow and Message option will be displayed when both of you are following each other."
                    }
                }
            },
            editProfile: {
                title: "Edit Profile Features",
                updateName: {
                    title: "1.1 Update Username and Display Name",
                    note: "Username should have at least 3 alphabets. Username shouldn’t start with an underscore and shouldn’t have consecutive underscores.",
                    username: {
                        title: "Update Username:",
                        step1: "Update your Username in the Edit Profile page’s username field.",
                        step2: "Click the save button on the Edit profile page.",
                        step3: "Username will be updated."
                    },
                    displaName: {
                        title: "Update Display Name:",
                        step1: "Update your Display name in the Edit Profile page’s display name field.",
                        step2: "Click the save button on the Edit profile page.",
                        step3: "Display name will be updated."
                    }
                },
                updateProfilePic:{
                    title: "1.2 Update or Remove Profile Picture",
                    step1: "Click the Edit picture option in the Edit profile page.",
                    step2: "Click the “Remove current photo” option to remove your profile pic.",
                    step3: "Click the “Choose from gallery” option to select the profile pic from the gallery.",
                    step4: "Click the “Take photo” option to open the camera.",
                    step5: "Click the Save button to update or remove the profile picture once the changes are done."
                },
                bioAndWebsite:{
                    title: "1.3 Add Bio and Website Link",
                    bio:{
                        title: "Add Bio:",
                        step1: "In the Edit profile page’s Bio field, enter a description about you or your thoughts.",
                        step2: "Click the save button to update your profile bio."
                    },
                    website:{
                        title: "Add Website Link:",
                        step1: "In the Edit profile page, click the arrow button in the link field.",
                        step2: "Enter the URL of your website in the Add Your URL field.",
                        step3: "Enter the title of your website in the Title field.",
                        step4: "Click the ( \u2714 ) icon.",
                        step5: "Click the Save button to add the link in the Profile page."
                    }
                }
            }
        },
       
        settings:{
            title: "Adjust Your Settings",
            updateAccount:{
                title: "Update Your Account Settings",
                step1: "Click Account settings on the settings page.",
                step2: "In the Account settings page,",
                step3: "First name and last name can be edited.",
                step4: "Phone number will be displayed and not able to edit.",
                step5: "Email ID, DOB (Date of Birth), and Gender can be edited.",
                step6: "Click the “Update” Button to save the changes."
            },
            interest:{
                title: "Modify Your Home Feed Interest",
                step1: "Click the Interest option displayed on the settings page.",
                step2: "Able to check and uncheck the topics displayed on this page.",
                step3: "Click the “Update” button to save the changes."
            },
            notification:{
                title: "Notification Settings",
                step1: "Click the Push notification option displayed on the settings page.",
                step2: "On the push notification page, stop the push notification as described below:",
                step3: "If you want to stop the post likes notification alone, click the likes and select the off checkbox.",
                step4: "If you want to stop the post comments notification alone, click the comments and select the off checkbox.",
                step5: "If you want to stop the comments like notification alone, click the comments likes and select the off checkbox.",
                step6: "If you want to stop all notifications, enable the Pause All toggle button on the push notification page.",
                step7: "On the push notification page, view the push notification of your following alone:",
                step8: "If you want to view the post likes notification of your following users alone, click the likes and select the from profiles I follow checkbox.",
                step9: "If you want to view the post comments notification of your following users alone, click the comments and select the from profiles I follow checkbox.",
                step10: "If you want to view the comments like notifications of your following users alone, click the comments likes and select the from profiles I follow checkbox.",
                step11: "On the push notification page, view the push notification of all users:",
                step12: "If you want to view the post likes notification from everyone, click the likes and select the from everyone checkbox.",
                step13: "If you want to view the post comments notification from everyone, click the comments and select the from everyone checkbox.",
                step14: "If you want to view the comments likes notification from everyone, click the comments likes and select the from everyone checkbox.",
                note: "To view the push notification, the Pause All toggle button on the push notification page should be disabled."
            },
            language:{
                title: "Switch the Language",
                step1: "Click the Language option displayed on the settings page.",
                step2: "Select the Language you prefer.",
                step3: "Click “Yes” in the confirmation popup to switch the application language."
            },
            linkAccount: {
                title: "Link Account With Web Page",
                step1: "To link the account with the web page, enter the URL <a> web.enkrip.com </a>  in the website field.",
                step2: "Select the Linked Device option on the settings page.",
                step3: "Scan the QR code displayed on the website.",
                step4: "Account will be linked to the webpage successfully."
            },
            inviteFriends: {
                title: "Invite Your Friends",
                step1: "Click the Invite Friends option displayed on the settings page.",
                step2: "Share the link with your Non-Indonesian friends to log in to the ENKRIP application."
            },
            logout: {
                title: "Logout",
                step1: "Select the Logout option displayed on the settings page.",
                step2: "In the confirmation popup, select Backup Now and click the backup button if you want to take chat backup and log out.",
                step3: "In the confirmation popup, select ignore if you want to log out without a backup."
            }
        },
        communicate: {
            title: "Features to Communicate",
            post: {
                title: "Create and Share Post",
                createPost: {
                    title: "Create a Post :",
                    step1: "Click the (+) icon displayed in the footer tab.",
                    step2: "Select the Take a Photo option if you want to open the camera.",
                    step3: "Capture the image and enter a caption.",
                    step4: "Click the Post button to post successfully.",
                    step5: "Launch the application.",
                    step6: "Click the (+) icon displayed in the footer tab.",
                    step7: "Select the Photos and Videos option if you want to open the gallery.",
                    step8: "Select the photos/videos/mix of photos and videos with a limit of 10 and below.",
                    step9: "After selecting the photos and videos, click the ( \u2714 ) icon.",
                    step10: "View the selected photos and videos and again click the ( \u2714 ) icon.",
                    step11: "Enter a caption and click the Post button to post successfully."
                },
                sharePost: {
                    title: "Share a Post :",
                    step1: "Click the share button displayed under each post near the comment button.",
                    step2: "Share the post link with your friends through any of the messenger apps."
                },
                exploreView:{
                    title: "Explore and View Post",
                    step1: "Click the ( <SearchIcon /> ) icon displayed in the footer tab.",
                    step2: "View the trending post displayed on the Explore page.",
                    step3: "Click the search field and enter the account name/post caption/hashtag you requested for the search.",
                    step4: "Click the search icon once the requested text is entered.",
                    step5: "In the For You tab, the account name and post caption similar to the requested text will be displayed.",
                    step6: "In the Accounts tab, the account name similar to the requested text will be displayed.",
                    step7: "In the hashtag page, a hashtag similar to the requested text will be displayed."
                }
            },
            webchat:{
                title: "Chats and Calls",
                newChat: {
                    title: "To start a New chat :",
                    step1: "Click the chat icon displayed in the footer page.",
                    step2: "Click the (+) icon displayed in the top right corner of the page.",
                    step3: "Select the requested user from My Contacts if the user's phone number is saved in the phonebook.",
                    step4: "Select the requested user from Mutual friends if you and the user are mutual followers."
                },
                chats:{
                    title: "Chats :",
                    step1: "Click the chat icon displayed in the footer page.",
                    step2: "Select any of the users.",
                    step3: "To send a message, enter the message and click the send icon.",
                    step4: "To capture a photo/video and share, select the camera icon (OR) select the attachments icon – Camera option.",
                    step5: "To share the gallery photo and videos, select the attachments icon – gallery option.",
                    step6: "To share the document, select the attachment icon – document option.",
                    step7: "To share the audio, select the attachment icon – audio option.",
                    step8: "To share the contacts, select the attachment icon – Contacts option.",
                    step9: "To share the location, select the attachment icon – location option.",
                    step10: "To share the gif, select the gif icon and select the requested gif.",
                    step11: "To share the emoji, select the emoji icon and enter the emoji needed to share."
                },
                calls:{
                    title: "Calls :",
                    step1: "Click the chat icon displayed in the footer page.",
                    step2: "Select any of the users.",
                    step3: "In the user’s chat page, click the audio call icon for audio call.",
                    step4: "In the user’s chat page, click the video call icon for video call."
                }
            }
        }
    }
}