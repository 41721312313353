import OutsideClickHandler from "features/OutsideClickHandler";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { CloseIcon, MenuIcon } from "Website/assets/images";
import Menu from "Website/Menu/Components/Menu";
import HeaderRight from "./HeaderRight";
import Logo from "./Logo";
import HelpSidebar from "Website/Pages/Help/Components/HelpSidebar";
import { DEVICE_TYPE_DESKTOP } from "features/App/constants";
import { isHelpPage } from "Website/Pages/Help/helpers";
import { isPolicyPage } from "Website/Pages/Policy/helper";
import PolicySidebar from "Website/Pages/Policy/Components/PolicySidebar";

const Header = (props) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if(showMobileMenu){
            setShowMobileMenu(false);
        }
    },[props.selectedLang, location]);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, [location]);

    const helpPage = isHelpPage(location.pathname);
    const policyPage=isPolicyPage(location.pathname)
    
    return (
        <header>
            <Logo />
            <Menu deviceType={DEVICE_TYPE_DESKTOP} />
            <HeaderRight deviceType={DEVICE_TYPE_DESKTOP} handleSetLanguage={props.handleSetLanguage} selectedLang={props.selectedLang} />
            <i className="icon menu_icon mobile" onClick={() => setShowMobileMenu(!showMobileMenu)} data-id="mobile-header-menu">
                {!showMobileMenu && <MenuIcon/>}
                {showMobileMenu && <CloseIcon/>}
            </i>
            {showMobileMenu && <OutsideClickHandler onOutsideClick={() => setShowMobileMenu(false)} outsideClickBlockId={"mobile-header-menu"}>
                <div className="mobile_navigation">
                    {!helpPage && !policyPage && <><Menu />
                    </>}
                    {helpPage && <HelpSidebar />}
                    {policyPage && <PolicySidebar/>}
                    <HeaderRight handleSetLanguage={props.handleSetLanguage} selectedLang={props.selectedLang} />
                </div> 
            </OutsideClickHandler>}
        </header>
    )
}

export default Header;