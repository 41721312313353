import { isMobileDevice } from "features/App/helpers";
import { useTranslation } from "react-i18next";
import { HomeMenu } from "Website/Menu/Components/Menu";
import NavigationLink from "Website/Layout/Components/NavigationLink";
import { STAY_SAFE } from "../constants";
import { CHILD_PROTECTION } from "../constants";
const PolicySidebar = (props) => {
    const isMobile = isMobileDevice(props.deviceType);
    const { t } = useTranslation();
    return (
        <>
            <ul className={`${isMobile ? "header_nav" : ""}`}>
                {isMobile && <li><HomeMenu t={t} /></li>}
                <li><NavigationLink to={STAY_SAFE} label={t("menu.policySubMenu.staySafe")} /></li>
                <li><NavigationLink to={CHILD_PROTECTION} label={t("menu.policySubMenu.childProtection")} /></li>
               
               
                
            </ul>
        </>
    )
}
export default PolicySidebar;