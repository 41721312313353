import { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { DEFAULT_LANGUAGE } from "Website/Language/constants";
import i18n from "features/Languages/i18n";
import { getSelectedLang } from "Website/Language/helpers";
import { useLocation } from "react-router-dom";
import { isHelpPage } from "Website/Pages/Help/helpers";
import { isPolicyPage } from "Website/Pages/Policy/helper";

const Layout = ({children}) => {
    const selectedLangStr = getSelectedLang(i18n.language);
    const [selectedLang, setSelectedLang] = useState(selectedLangStr || DEFAULT_LANGUAGE);
    const location = useLocation();
    const helpPage = isHelpPage(location.pathname);
    const policyPage=isPolicyPage(location.pathname)

    const handleSetLanguage = (selectedLang) => {
        i18n.changeLanguage(selectedLang).then(() => {
            setSelectedLang(selectedLang);
        });
    }

    return (
        <>
            <Header handleSetLanguage={handleSetLanguage} selectedLang={selectedLang} />
                <div className={`content_section ${helpPage || policyPage ? 'help_page' : ''}`}>
                    {children}
                </div>
                {!helpPage && !policyPage && <Footer handleSetLanguage={handleSetLanguage} selectedLang={selectedLang}/>}
        </>
    )
}

export default Layout;